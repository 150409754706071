// DEMO
.divfloatleft{
  float:left;
}
.oculto{
  display:none;
}
.size_unidad{
  font-size: 0.6rem;
  
}
.ancho_btn{
  width: 69px;
  text-align:left;
  margin-left: 10px;
  
}
.size_unidad2{
  font-size: 0.4rem;
  
}

.size_prom{
  font-size: 10px;
  font-weight: bold;
}

.size_unidad3{
  font-size: 0.9rem;
  
}
.size_label{
  font-size: 0.5rem;
  
}
.size-boton{
   font-weight: bold;
    font-size: 1.1rem;
    display: block;
    line-height: 1;
    margin: $widget-spacer auto;
}
.altografico{
  height: 130px;
}
.size_textbomba{
  font-size: 0.7rem;
}


.canvasjs-chart-canvas{
  padding: 0px;
  margin: 0px;
}
.card-btm-border1 {
  border-bottom: transparent solid 2px;
}

.card-btm-border2 {
  border-bottom: transparent solid 1px;
}


.logocliente {
  position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding-left: 40px;
    padding-bottom: 20px;  
    z-index: 7;
}
// DEMO
.form-group {
  margin-bottom: 0;
}

.body-tabs {
  border-bottom: $gray-300 solid 2px;

  & > .RRT__tab {
    padding: ($layout-spacer-x / 1.5) ($layout-spacer-x / 1.2);
    font-weight: normal;
    font-size: $font-size-base;
  }

  &.body-tabs-alt2 {
    & > .RRT__tab {
      padding: ($layout-spacer-x / 1.1) ($layout-spacer-x / 3);
      font-weight: normal;
      font-size: 0.86rem;
      width: 48%;
      text-align: center;
    }

    .RRT__inkbar {
      height: 2px;
      margin-top: -2px;
    }
  }
}

@import "elements/theme-options";

// Icons demo

.font-icon-wrapper {
  text-align: center;
  border: $gray-200 solid 1px;
  @include border-radius($border-radius);
  margin: 0 0 10px;
  padding: 5px;

  &.font-icon-lg {
    float: left;
    padding: 10px;
    text-align: center;
    margin-right: 15px;
    min-width: 64px;

    i {
      font-size: $h1-font-size;
    }
  }

  &:hover {
    background: $gray-100;
    color: $primary;

    p {
      color: $gray-600;
    }
  }

  i {
    font-size: ($font-size-lg * 1.5);
  }

  p {
    color: $gray-500;
    font-size: ($font-size-sm / 1.2);
    margin: 5px 0 0;
  }
}

.btn-icon-vertical {
  min-width: 100px;
}

.card.mb-3 {
  margin-bottom: $grid-gutter-width !important;
}

// Image BG

.demo-image-bg {
  height: 350px;
  margin-bottom: $grid-gutter-width;
}

// Loaders wrapper

.loader-wrapper {
  width: 150px;
  height: 100px;
  float: left;
}

// Slick slider

.slider-item {
  background: $gray-300;
  @include border-radius($border-radius);
  color: $gray-600;
  font-size: 36px;
  padding: 0;
  position: relative;
  height: 150px;
  line-height: 150px;
  text-align: center;
  margin: 0 $layout-spacer-x;
  transition: all .2s;
}

.slick-center {
  .slider-item {
    background: $gray-500;
    color: $gray-700;
  }
}

// Image Crop

.after-img {
  max-width: 100%;
}
